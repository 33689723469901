import React from 'react';
import PropTypes from 'prop-types';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import {
  RootWrapper,
  ContactDetailWrapper,
  IconWrapper,
} from './personal.style';
import OlafImage from 'common/src/assets/image/saas/olaf_profiel.png';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_call } from 'react-icons-kit/md/ic_call';
import Icon from 'react-icons-kit';
import LinkedInIcon from 'common/src/assets/image/saas/linkedin_icon.inline.svg';
import { ic_person } from 'react-icons-kit/md/ic_person';
import Divider from '@material-ui/core/Divider';
import Calendly from '../calendly';
import PwrdBy from 'common/src/assets/image/saas/pwrdBy.svg';
import Grid from '@material-ui/core/Grid';

const images = {
  OlafImage,
};

const personDetails = {
  phone: '+316 51 66 56 24',
  email: 'olaf.aalsum@salesocean.io',
  image: 'OlafImage',
  name: 'Olaf van Aalsum',
  linkedin: 'https://www.linkedin.com/in/olaf-van-aalsum-699524119/',
  openingLine: 'Leuk dat je verder in contact wil komen!',
  calendly: 'https://calendly.com/olaf-aalsum/15min',
  text: [
    {
      subtitle: 'Wat doet SalesOcean?',
      text:
        'Samen met een vriend ben ik een startup gestart; SalesOcean. We hebben een sales development platform gebouwd dat het lead generation proces voor sales teams overzichtelijker en efficiënter maakt. Uit mijn eigen ervaring weet ik hoe lastig het kan zijn het overzicht te houden als er honderden klanten en campagnes door elkaar heen lopen.',
    },
    {
      subtitle: 'Wat is mijn rol?',
      text:
        'Als business consultant bij SalesOcean ben ik dagelijks in gesprek met commerciële personen die actief zijn in de B2B markt om zijn/haar uitdagingen m.b.t. lead generation te bespreken. Dit varieert tussen lead generation bedrijven, SaaS bedrijven en tech startups.',
    },
    {
      subtitle: 'Waar ligt mijn focus?',
      text:
        'De meeste energie uit mijn werk haal ik door het samen met de klant te kijken naar de uitdaging die zij ervaren en welke obstakels hierbij komen kijken. Bij SalesOcean ligt de focus écht op de klant, is er veel ruimte voor persoonlijke hulp en ben ik in staat om te doen wat ik leuk vind, klanten zo goed mogelijk van dienst kunnen zijn.',
    },
  ],
};

const OlafSection = ({
  title,
  textArea,
  imageStyle,
  iconStyle,
  subtitle,
  smallContactText,
}) => {
  return (
    <RootWrapper>
      <Container width="100%">
        <Grid container>
          <Grid item md={5} lg={3}>
            <img
              src={images[personDetails.image]}
              style={imageStyle}
              alt="Olaf profile"
            />
            <ContactDetailWrapper>
              <Text
                iconStart={
                  <IconWrapper>
                    <Icon icon={ic_person} style={{ ...iconStyle }} />
                  </IconWrapper>
                }
                content={personDetails.name}
                {...smallContactText}
              />
              <Divider />

              <Text
                iconStart={
                  <IconWrapper>
                    <Icon
                      icon={ic_email}
                      style={{ color: '#52bd95', ...iconStyle }}
                    />
                  </IconWrapper>
                }
                content={
                  <a href={`mailto:${personDetails.email}`}>
                    {personDetails.email}
                  </a>
                }
                {...smallContactText}
              />
              <Divider />
              <Text
                iconStart={
                  <IconWrapper>
                    <Icon
                      style={{ color: '#2962ff', ...iconStyle }}
                      icon={ic_call}
                    />
                  </IconWrapper>
                }
                content={
                  <a href={`tel:${personDetails.phone}`}>
                    {personDetails.phone}
                  </a>
                }
                {...smallContactText}
              />
              <Divider />

              <Text
                iconStart={
                  <IconWrapper>
                    <LinkedInIcon
                      style={{ ...iconStyle, borderRadius: '5px' }}
                    />
                  </IconWrapper>
                }
                content={
                  <a href={personDetails.linkedin} target="_blank">
                    {personDetails.name}
                  </a>
                }
                {...smallContactText}
              />
            </ContactDetailWrapper>
          </Grid>
          <Grid
            item
            lg={8}
            md={7}
            style={{ marginBottom: '20px', padding: '0 1rem' }}
          >
            <Heading content={personDetails.openingLine} {...title} />
            {personDetails.text.map(text => (
              <>
                <Text content={text.subtitle} {...subtitle} />
                <Text content={text.text} {...textArea} />
              </>
            ))}
            <div>
              <Calendly link={personDetails.calendly} />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={3}></Grid>
        </Grid>
      </Container>

      <div
        style={{
          position: 'fixed',
          right: '25px',
          top: '12rem',
        }}
      >
        <img
          src={PwrdBy}
          style={{
            width: '15rem',
            marginBottom: '1rem',
            marginTop: '-5rem',
          }}
        />
      </div>
    </RootWrapper>
  );
};

OlafSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

OlafSection.defaultProps = {
  imageStyle: {
    width: '90%',
    borderRadius: '5px',
    //boxShadow: '#8080804a 0px 0px 10px 10px',
    marginBottom: '20px',
    marginTop: '8px',
  },
  iconStyle: {
    width: '30px',
  },
  textArea: {
    fontSize: '17px',
    display: 'flex',
    alignItems: 'center',
    margin: ' 0 0 2rem 0 !important',
  },
  smallContactText: {
    display: 'flex',
    fontSize: '17px',
    margin: ' 0 0 0.5rem 0 !important',
    style: {
      alignItems: 'center',
    },
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    marginTop: ['2rem', '3.5rem'],
  },
  title: {
    fontSize: ['20px', '26px', '26px'],
    fontWeight: '500',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    lineHeight: '1.5',
  },
  subtitle: {
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    margin: '0 0 !important',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default OlafSection;
