import styled from 'styled-components';

export const RootWrapper = styled.section`
  padding: 10rem 0;
  width: 80%;
  margin: auto;
  display: flex;
  @media (max-width: 700px) {
    .container {
      width: 80%;
      margin: auto !important;
      padding: 0;
    }
  }
  @media (max-width: 400px) {
    .container {
      width: 80%;
      margin: auto !important;
      padding: 0;
    }
  }
`;

export const LeftBlockWrapper = styled.div`
  width: 25%;
  display: inline-block;
`;

export const ContactDetailWrapper = styled.div``;

export const RightBlockWrapper = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0 2rem;
`;

export const CalendlyBlock = styled.div`
  width: 25%;
  display: inline-block;
  @media (max-width: 1400px) {
    width: 100%;
    padding: 1rem 5rem;
  }
`;

export const IconWrapper = styled.span`
  margin-right: 20px;
  svg {
    height: 40px;
    width: 40px;
  }
`;
