import React from 'react';

const Calendly = ({
  link = 'https://calendly.com/seppe-dalen/15-min-call?hide_landing_page_details=1',
}) => {
  return (
    <div style={{ height: '1200px' }}>
      <iframe src={link} width="100%" height="100%" frameborder="0"></iframe>
    </div>
  );
};

export default Calendly;
